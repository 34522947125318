import { memo } from 'react'
import styled from 'styled-components'

export const StyledSpinner = styled.svg.attrs<{
  width: number
  height: number
}>(({ width, height }) => ({
  style: {
    width: `${width}px`,
    height: `${height}px`,
    marginTop: `-${height / 2}px`,
  },
}))`
  animation: rotate 1s linear infinite;

  & .path {
    stroke: #fff;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`

const StyledWrapper = styled.div`
  z-index: 1;
  display: grid;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  top: 0;
  left: 0;
`

const Spiner = ({
  width = 50,
  height = 50,
}: {
  width?: number
  height?: number
}) => {
  return (
    <StyledWrapper>
      <StyledSpinner width={width} height={height} viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="4"
        />
      </StyledSpinner>
    </StyledWrapper>
  )
}

export default memo(Spiner)
