import '@fontsource/maven-pro/latin-600.css'
import '@fontsource/maven-pro/latin-700.css'
import '@fontsource/maven-pro/latin-800.css'
import '@fontsource/maven-pro/latin-900.css'
import 'core-js/es'
import { lazy, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import setAnalytics from './utils/analitycs'
import Spinner from '@src/components/StyledLoader'
import { Globals } from '@react-spring/web'

setAnalytics()

const rootNode = document.getElementById('root')
const path = location.href.split('/')
const isGameProducer = path.at(3) === 'producer'
const isGameStage = path.at(3) === 'game'
const gameID = path.at(4)
const isCanvasEnabled = path.at(5) === 'p'

window.localStorage.setItem('forceCanvas', isCanvasEnabled ? '1' : '0')

const App = lazy(() => import('./App'))
const Stage = lazy(() => import('./Stage'))
const Producer = lazy(() => import('./producer/App'))

const root = createRoot(rootNode)

Globals.assign({ skipAnimation: true })

root.render(
  <Suspense fallback={<Spinner />}>
    {isGameProducer ? (
      <BrowserRouter basename={'/producer'}>
        <Producer key={`producer${gameID}`} gameCode={gameID} />
      </BrowserRouter>
    ) : isGameStage ? (
      <Stage key="stage" gameID={gameID} />
    ) : (
      <App />
    )}
  </Suspense>,
)
